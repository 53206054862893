@tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    html {
      scroll-behavior: smooth;
    }
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;
      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;
      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;
      --primary: 221.2 83.2% 53.3%;
      --primary-foreground: 210 40% 98%;
      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;
      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;
      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;
      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;
      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 221.2 83.2% 53.3%;
      --radius: 1rem;
    }
  
    .dark {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;
      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;
      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;
      --primary: 217.2 91.2% 59.8%;
      --primary-foreground: 222.2 47.4% 11.2%;
      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;
      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;
      --accent: 217.2 32.6% 17.5%;
      --accent-foreground: 210 40% 98%;
      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 40% 98%;
      --border: 217.2 32.6% 17.5%;
      --input: 217.2 32.6% 17.5%;
      --ring: 224.3 76.3% 48%;
    }
  }

  /* Dark theme for React Quill */
.ql-toolbar.ql-snow {
  background-color: #fff;
  border: none;
}

/* General container styling */
.post-content {
  max-width: 100%;
  height: auto;
  font-family: sans-serif;
  line-height: 1.6;
  color: #f1f1f1;
}

/* Paragraph styling */
.post-content p {
  margin-bottom: 0.5rem;
}

/* Headings */
.post-content h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1.5rem 0;
}

.post-content h2 {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1.5rem 0;
}

.post-content h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 1.2rem 0;
}

.post-content h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1.1rem 0;
}

.post-content h5, .post-content h6 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 1rem 0;
}

/* Links */
.post-content a {
  color: rgb(73, 149, 199);
  text-decoration: none;
}

.post-content a:hover {
  text-decoration: underline;
}

/* List styling */
.post-content ul,
.post-content ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.post-content ul {
  list-style-type: disc; /* Bullet points for unordered lists */
}

.post-content ol {
  list-style-type: decimal; /* Numbered points for ordered lists */
}

.post-content li {
  margin-bottom: 0.3rem;
}

/* Nested lists */
.post-content ul ul,
.post-content ol ol,
.post-content ul ol,
.post-content ol ul {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}

.post-content ul ul {
  list-style-type: circle; /* Circle bullets for nested unordered lists */
}

.post-content ol ol {
  list-style-type: lower-alpha; /* Lowercase letters for nested ordered lists */
}


/* Blockquotes */
.post-content blockquote {
  border-left: 4px solid rgb(73, 149, 199);
  padding-left: 1rem;
  color: #aaa;
  font-style: italic;
  margin: 1rem 0;
}

/* Code blocks */
.post-content code {
  background-color: #333;
  color: #f39c12;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
}

.post-content pre {
  background-color: #333;
  color: #f39c12;
  padding: 1rem;
  border-radius: 6px;
  overflow-x: auto;
}

/* Images */
.post-content img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin: 1rem 0;
}

/* Dark theme adjustments */
.dark .post-content {
  color: #f1f1f1;
}

.dark .post-content a {
  color: #f39c12;
}

.dark .post-content blockquote {
  border-left-color: #f39c12;
}

.overflow-x-auto {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

.overflow-x-auto::-webkit-scrollbar {
  height: 6px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

  

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply dark bg-background text-foreground;
    }
  }


